import { LeadEventType } from '@bridebook/toolbox/src/supplier/activity/types';

export const ApiEndpoint = {
  analytics: {
    identifySupplier: '/api/supplier-level/identify',
    identifySupplierClaimed: '/api/supplier-claimed/identify',
    track: '/api/a/t',
  },
  coupleIntel: (supplierId: string) => `/api/supplier/${supplierId}/couple-intel`,
  yourCompetitors: (supplierId: string) => `/api/supplier/${supplierId}/lost-bookings-to`,
  search: '/api/search',
  ledgerTransaction: '/api/supplier/ledger/transaction',
  revealEnquiry: '/api/supplier/wedding/enquiry/reveal',
  login: '/api/login',
  logout: '/api/logout',
  ppe: {
    createCheckoutSession: '/api/ppe/create-checkout-session',
  },
  inbox: {
    chatToken: '/api/chat-token',
    sendAttachments: '/api/inbox/send-attachments',
    uploadFile: '/api/inbox/upload-file',
    syncConversation: '/api/inbox/sync-talk-conversation',
  },
  videoMeta: '/api/video-meta',
  chargebee: {
    getPlanOptions: '/api/chargebee/subscriptions/get-plan-options',
    products: '/api/chargebee/products',
    checkout: '/api/chargebee/checkout',
    manageSubscription: '/api/chargebee/manage-subscription',
    getSubscriptionInfo: '/api/chargebee/subscriptions/get-subscription-info',
    getSubscriptionUpdateCheckoutUrl:
      '/api/chargebee/subscriptions/get-subscription-update-checkout-url',
    getCustomer: (supplierId: string) => `/api/chargebee/customers/${supplierId}`,
    createCustomer: '/api/chargebee/customers/create-customer',
    createQuote: '/api/chargebee/quotes/create-quote',
    createQuoteForUpdate: '/api/chargebee/quotes/create-quote-for-update',
    addons: {
      checkAvailability: '/api/chargebee/addons/check-availability',
    },
  },
  setLocale: '/api/i18n/set-locale',
  premium: {
    toggleTier: '/api/premium/toggle-premium-tier',
    toggleAdditionalArea: '/api/premium/toggle-additional-area',
  },
  couplesActivity: (
    supplierId: string,
    page: number = 1,
    eventType?: LeadEventType,
    size: number = 50,
  ) =>
    `/api/supplier/${supplierId}/activity?page=${page}&size=${size}${
      eventType ? '&eventType=' + eventType : ''
    }`,
  collaboration: {
    getFiles: '/api/files/get',
  },
  apps: {
    instagram: {
      connect: '/api/apps/instagram/connect',
      disconnect: '/api/apps/instagram/disconnect',
    },
  },
  statistics: {
    monoquery: (supplierId: string) => `/api/supplier/${supplierId}/statistics/monoquery`,
  },
  user: {
    locale: '/api/i18n/get-user-locale',
    activeSupplier: '/api/access-control/get-user-active-supplier',
  },
  groupStats: (supplierId: string) => `/api/supplier/${supplierId}/group-stats`,
  groupConnections: (supplierId: string) => `/api/supplier/${supplierId}/get-group-connections`,
  tidyYourInbox: (supplierId: string) => `/api/supplier/${supplierId}/tidy-inbox`,
  generateROIReport: (supplierId: string) => `/api/supplier/${supplierId}/generate-roi-report`,
  lostLeadsStats: (supplierId: string) => `/api/supplier/${supplierId}/lost-leads-stats`,
  todayTaskFlow: (todayTaskFlowId: string) => `/api/today-task-flow?taskFlowId=${todayTaskFlowId}`,
  acknowledgeAllBookings: (supplierId: string) => `/api/inbox/bookings/${supplierId}/acknowledge`,
};
